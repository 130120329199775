// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Body
$body-bg: #f5f8fa;

// Borders

// Brand

// Typography
$font-family-sans-serif: "Raleway", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #636b6f;

// Navbar

// Buttons

// Inputs

// Panels