// Trying a grid system

container

.navbar {
  width: 32.5%;
  height: 100%;
  display: block;

}

.content {
  width: 66.5%;
  position: relative;
  height: 100%;  
}

