

// Variables
@import "vars";

// DOM Strucure
@import "structures";

html {
  font-family: $font-family-sans-serif;
}

.navbar {
  position: relative;
  display: block;
  float: left;
}

.container {
  position: relative;
  display: block;
  float: right;
  width: 70%;
}